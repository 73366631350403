<template>
  <v-app>
    <loading :active.sync="is_call_api"></loading>
    <div style="background-color: rgb(243 246 249)">
      <h1 class="mt-5 mb-5" style="color: #3f4254">
        Thống kê nộp muộn, chấm muộn
      </h1>
    </div>
    <div class="d-block p-10" style="border-bottom: 1px solid #ebedf3">
      <v-btn
        class="p-6 text-body-1"
        :color="tab_active === 1 ? '#6993FF' : '#E1E9FF'"
        :style="{ color: tab_active === 1 ? 'white' : '#B5B5C3' }"
        style="text-transform: none"
        @click="tab_active = 1"
      >
        Nộp bài
      </v-btn>
      <v-btn
        class="ml-2 p-6 text-body-1"
        :color="tab_active === 2 ? '#6993FF' : '#E1E9FF'"
        :style="{ color: tab_active === 2 ? 'white' : '#B5B5C3' }"
        style="text-transform: none"
        @click="tab_active = 2"
      >
        Chấm bài
      </v-btn>
    </div>

    <div class="d-block pl-5 pt-8">
      <v-row>
        <v-col cols="12">
          <v-row>
            <v-col
              cols="6"
              class="d-flex align-items-center pt-0 pb-0 m-0 mb-2 row"
            >
              <label class="text-body-1 font-weight-bold col-2"
                >Khoảng thời gian:</label
              >
              <div class="col-9 d-flex p-0">
                <div class="d-flex">
                  <label class="text-body-1 font-weight-medium mb-6 pt-4"
                    >Từ:</label
                  >
                  <date-picker
                    style=""
                    class="ml-2 mt-3"
                    v-model="date_start_input"
                    value-type="format"
                    format="YYYY-MM-DD"
                  />
                </div>
                <v-icon class="ml-2 mr-2 mb-3">mdi-arrow-right</v-icon>
                <div class="d-flex">
                  <label class="text-body-1 font-weight-medium mb-6 pt-4"
                    >Đến:</label
                  >
                  <date-picker
                    style=""
                    class="ml-2 mt-3"
                    v-model="date_end_input"
                    value-type="format"
                    format="YYYY-MM-DD"
                  />
                </div>
              </div>
            </v-col>
            <v-col cols="12" class="m-0 p-0"></v-col>
            <v-col cols="4" class="d-flex align-items-center pt-0 pb-0 m-0 row">
              <label class="text-body-1 font-weight-bold col-3"
                >Trang thái chấm bài:</label
              >
              <div class="col-7 p-0">
                <v-autocomplete
                  :items="status_ticket"
                  item-text="label"
                  item-value="value"
                  v-model="status_ticket_input"
                  solo
                  color="blue-grey lighten-2"
                  dense
                ></v-autocomplete>
              </div>
            </v-col>
            <v-col cols="4" class="d-flex align-items-center pt-0 pb-0 m-0 row">
              <label class="text-body-1 font-weight-bold col-3"
                >Giáo viên:</label
              >
              <div class="col-7 p-0">
                <v-autocomplete
                  :items="marker_tags"
                  item-text="name"
                  item-value="id"
                  v-model="marker_input"
                  solo
                  color="blue-grey lighten-2"
                  dense
                ></v-autocomplete>
              </div>
            </v-col>
            <v-col cols="12" class="m-0 p-0"></v-col>
            <v-col cols="4" class="d-flex align-items-center pt-0 pb-0 m-0 row">
              <label class="text-body-1 font-weight-bold col-3"
                >Khóa học:</label
              >
              <div class="col-7 p-0">
                <v-autocomplete
                  :items="course_tags"
                  item-text="name.vi"
                  item-value="id"
                  v-model="course_input"
                  solo
                  color="blue-grey lighten-2"
                  dense
                ></v-autocomplete>
              </div>
            </v-col>
            <v-col cols="4" class="d-flex align-items-center pt-0 pb-0 m-0 row">
              <label class="text-body-1 font-weight-bold col-3">Lớp học:</label>
              <div class="col-7 p-0">
                <v-autocomplete
                  :items="class_tags"
                  item-text="name"
                  item-value="id"
                  v-model="class_input"
                  solo
                  color="blue-grey lighten-2"
                  dense
                ></v-autocomplete>
              </div>
            </v-col>
            <v-col cols="4" class="d-flex align-items-center pt-0 pb-0 m-0 row">
              <label class="text-body-1 font-weight-bold col-3">Kỹ năng:</label>
              <div class="col-7 p-0">
                <v-autocomplete
                  :items="skill_tags"
                  item-text="name"
                  item-value="id"
                  v-model="skill_input"
                  solo
                  color="blue-grey lighten-2"
                  dense
                ></v-autocomplete>
              </div>
            </v-col>
            <v-col
              cols="4"
              class="d-flex align-items-center pt-0 pb-0 m-0 row"
              v-if="tab_active === 1"
            >
              <label class="text-body-1 font-weight-bold col-3"
                >Trang thái nộp bài:</label
              >
              <div class="col-7 p-0">
                <v-autocomplete
                  :items="status_submit"
                  item-text="label"
                  item-value="value"
                  v-model="status_submit_input"
                  solo
                  color="blue-grey lighten-2"
                  dense
                ></v-autocomplete>
              </div>
            </v-col>
            <v-col cols="12" class="m-0 p-0 text-center">
              <v-btn
                color="cyan"
                class="ma-2 font-weight-bold"
                outlined
                large
                @click="btnFilterData"
              >
                <v-icon left>mdi-magnify</v-icon> Tìm kiếm
              </v-btn>
              <v-btn
                color="red"
                class="ma-2 font-weight-bold"
                outlined
                large
                @click="btnResetFilterData"
              >
                <v-icon left>mdi-backup-restore</v-icon> Hủy lọc
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12">
          <template v-if="tab_active === 1">
            <SubmitStatus :marking_tickets="marking_tickets"></SubmitStatus>
          </template>
          <template v-if="tab_active === 2">
            <MarkingStatus
              :data_marking="data_marking_by_status"
            ></MarkingStatus>
          </template>
        </v-col>
        <v-col cols="6" v-if="tab_active === 1">
          <v-pagination
            style="float: left"
            color="#00BCD4"
            v-model="data_pagination.current_page"
            :length="data_pagination.total_pages"
            :total-visible="6"
          ></v-pagination>
        </v-col>
      </v-row>
    </div>
  </v-app>
</template>

<script>
import ApiService from "@/service/api.service";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  name: "Tab",
  components: {
    Loading: () => import("vue-loading-overlay"),
    SubmitStatus: () =>
      import(
        "@/view/pages/marking-management/StatisticalDeadline/SubmitStatus"
      ),
    MarkingStatus: () =>
      import(
        "@/view/pages/marking-management/StatisticalDeadline/MarkingStatus"
      ),
  },
  data() {
    return {
      is_call_api: false,
      tab_active: 1,
      status_ticket: [
        { label: "Toàn bộ", value: 0 },
        { label: "Đã chấm", value: 3 },
        { label: "Đang chấm", value: 2 },
        { label: "Chưa chấm", value: 1 },
      ],
      status_ticket_input: 0,
      status_submit: [
        { label: "Nộp đúng hạn", value: 1 },
        { label: "Nộp muộn", value: 2 },
      ],
      status_submit_input: 1,
      date_start_input: null,
      date_end_input: null,
      class_tags: [],
      class_input: null,
      skill_tags: [],
      skill_input: null,
      course_tags: [],
      course_input: null,
      marker_tags: [],
      marker_input: null,
      marking_tickets: [],
      data_pagination: {
        total: 0,
        current_page: 1,
        total_pages: 1,
      },
      data_marking_by_status: [],
    };
  },
  watch: {
    tab_active() {
      this.checkApiStatistical();
    },
  },
  created() {
    this.resetDate();
    this.getAllClass();
    this.getAllSkill();
    this.getAllCourse();
    this.getListMarker();
    this.checkApiStatistical();
  },
  methods: {
    resetDate() {
      let currentDayOfMonth = new Date();
      let firstDayOfMonth = new Date(
        currentDayOfMonth.getFullYear(),
        currentDayOfMonth.getMonth(),
        1
      );
      this.date_start_input = this.convertOjDateToYMD(firstDayOfMonth);
      this.date_end_input = this.convertOjDateToYMD(currentDayOfMonth);
    },
    async getAllClass() {
      let vm = this;
      await ApiService.get("prep-app/class").then(function (response) {
        if (response.status === 200) {
          vm.class_tags = response.data;
        }
      });
    },
    async getAllSkill() {
      let vm = this;
      await ApiService.get("prep-app/tag/list-skill").then(function (response) {
        if (response.status === 200) {
          vm.skill_tags = response.data;
        }
      });
    },
    async getAllCourse() {
      let vm = this;
      await ApiService.get("prep-app/courses/limit").then(function (response) {
        if (response.status === 200) {
          vm.course_tags = response.data;
        }
      });
    },
    getListMarker() {
      let vm = this;
      ApiService.get("prep-app/markers").then(function (response) {
        if (response.status === 200) {
          vm.marker_tags = response.data;
        }
      });
    },
    btnFilterData() {
      this.checkApiStatistical();
    },
    btnResetFilterData() {
      this.resetDate();
      this.status_ticket_input = 0;
      this.marker_input = null;
      this.course_input = null;
      this.class_input = null;
      this.skill_input = null;
      this.status_submit_input = 1;
    },
    checkApiStatistical() {
      if (this.tab_active === 1) {
        // gọi api thống kê các ticket nộp muộn
        this.getDataStatisticalSubmit(this.data_pagination.current_page);
      } else {
        this.getDataStatisticalMarking();
      }
    },
    async getDataStatisticalSubmit(page = 1) {
      let vm = this;
      let url =
        "prep-app/marking-ticket/statistic-deadline/submit?page=" + page;
      let checkUrl = this.checkUrlMarkingTicket(url);
      this.is_call_api = true;
      await ApiService.get(checkUrl).then(function (res) {
        if (res.status === 200) {
          vm.marking_tickets = res.data.data;
          vm.data_pagination = res.data.meta.pagination;
          vm.is_call_api = false;
        }
      });
    },
    async getDataStatisticalMarking() {
      let vm = this;
      let url = "prep-app/marking-ticket/statistic-deadline/marking?page=";
      let checkUrl = this.checkUrlMarkingTicket(url);
      this.is_call_api = true;
      await ApiService.get(checkUrl).then(function (res) {
        if (res.status === 200) {
          vm.data_marking_by_status = res.data;
          vm.is_call_api = false;
        }
      });
    },
    checkUrlMarkingTicket(url) {
      if (
        !(
          this.status_submit_input === "" ||
          this.status_submit_input === undefined ||
          this.status_submit_input == null
        )
      ) {
        url = url + "&submit_status=" + this.status_submit_input;
      }
      if (
        !(
          this.status_ticket_input === "" ||
          this.status_ticket_input === undefined ||
          this.status_ticket_input == null
        )
      ) {
        if (this.status_ticket_input !== 0) {
          url = url + "&status=" + this.status_ticket_input;
        }
      }
      if (
        !(
          this.marker_input === "" ||
          this.marker_input === undefined ||
          this.marker_input == null
        )
      ) {
        url = url + "&marker=" + this.marker_input;
      }
      if (
        !(
          this.skill_search_input === "" ||
          this.skill_search_input === undefined ||
          this.skill_search_input == null
        )
      ) {
        url = url + "&skill_name=" + this.skill_input;
      }
      if (
        !(
          this.class_search_input === "" ||
          this.class_search_input === undefined ||
          this.class_search_input == null
        )
      ) {
        url = url + "&class_name=" + this.class_input;
      }
      if (
        !(this.date_start_input === null || this.date_start_input === undefined)
      ) {
        url = url + "&date_start=" + this.date_start_input;
      }
      if (
        !(this.date_end_input === null || this.date_end_input === undefined)
      ) {
        url = url + "&date_end=" + this.date_end_input;
      }
      return url;
    },
    convertOjDateToYMD(oj_date) {
      let year = oj_date.getFullYear();
      let month = ("0" + (oj_date.getMonth() + 1)).slice(-2);
      let day = ("0" + oj_date.getDate()).slice(-2);
      return year + "-" + month + "-" + day;
    },
  },
};
</script>

<style scoped></style>
